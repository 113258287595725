<template>
	<div class="anchor_organization">
		<el-card class="mb20">
			<div class="flex_r f_jc_b account p20">
				<div class="flex_r f_ai_c pl10">
					<div class="posr account_img">
						<img class="brs_4" height="88" width="88" src="https://filenew.lxshuju.com/618121/20201118/b41049894f843f361a529ffa1af1f151.jpg" alt="">
						<span class="posa fs10 tc cr_fff">未认证</span>
					</div>
					<div class="flex_c f_jc_c pl15">
						<span class="fs18">诗洁sj</span>
						<span class="pb10 pt10">ID：618121</span>
						<span>直播平台： 抖音</span>
					</div>
				</div>
				<div>
					<el-button type="primary" class="mt30" @click="$refs.dialogRef.handleOpen()">绑定机构</el-button>
				</div>
			</div>
		</el-card>
		<el-radio-group v-model="tabPosition" style="margin-bottom: 30px;">
			<el-radio-button label="1">商家未签约</el-radio-button>
			<el-radio-button label="2">待审核</el-radio-button>
			<el-radio-button label="3">已完成</el-radio-button>
			<el-radio-button label="4">审核不通过</el-radio-button>
		</el-radio-group>
		<e-table :tableCols="anchorOrganizationTableConfItem" :showOperation="false" :dataOrigin="dataOrigin">
			<template #1-1>
				<div class="flex_r f_ai_c f_jc_c">
					<img class="dis_i mr10" width="60" height="60" src="" alt="">
					<div>
						<div class="pb6">鲲致千程</div>
						<span>ID：11111</span>
					</div>
				</div>
			</template>
			<template #1-2>
				<div class="flex_c f_ai_c f_jc_c">
					<span class="pb6">商家数： 0</span>
					<span>商品数： 0</span>
				</div>
			</template>
			<template #1-3>
				<div class="flex_c f_ai_c f_jc_c">
					<span class="pb6">商家数： 0</span>
					<span>商品数： 0</span>
				</div>
			</template>
			<template #1-6>
				<div class="flex_c f_ai_c f_jc_c">
					<span class="cur_p">解除绑定</span>
					<span class="mt6 mb6 cur_p" @click="$refs.dialogAuthRef.handleOpen()">授权商家信息</span>
					<span class="cur_p" @click="$refs.dialogLogRef.handleOpen()">查看日志</span>
				</div>
			</template>
			<template #4-6>
				<div class="flex_c f_ai_c f_jc_c">
					<span class="cur_p" @click="$refs.dialogLogRef.handleOpen()">查看日志</span>
				</div>
			</template>
		</e-table>
		<e-dialog title="" ref="dialogRef">
			<div class="flex_r f_ai_c">
				<span class="w_100">机构ID：</span>
				<el-input placeholder="请输入机构ID"></el-input>
			</div>
			<div class="flex_r f_ai_c mt20 mb20">
				<span class="w_100">机构名称：</span>
				<div></div>
			</div>
			<div class="flex_r">
				<span class="w_100">机构图片：</span>
				<img width="100" height="100" src="" alt="">
			</div>
		</e-dialog>
		<e-dialog title="查看日志" ref="dialogLogRef">
			<el-timeline>
				<el-timeline-item>
					<div class="pl20 pt20 pr20 pb20 box_s">
						<div>坤恒信息科技系统管理员操作绑定成功</div>
					</div>
				</el-timeline-item>
			</el-timeline>
		</e-dialog>
		<e-dialog width="1000px" title="商家授权管理" ref="dialogAuthRef">
			<div>
				<div class="search">
					<el-tabs v-model="activeAuthName" @tab-click="handleClick">
						<el-tab-pane label="未授权" name="1"></el-tab-pane>
						<el-tab-pane label="已授权" name="2"></el-tab-pane>
					</el-tabs>
					<el-form label-width="auto" class="form flex_r">
						<el-form-item label="商家名称">
							<el-input></el-input>
						</el-form-item>
						<el-form-item label="店铺星级" class="ml10 mr10">
							<el-select>
								<el-option label="1" value="1"></el-option>
							</el-select>
						</el-form-item>
						<div>
							<el-button type="primary">搜索</el-button>
						</div>
					</el-form>
				</div>
				<div class="flex_r f_jc_b pb10 pl20">
					<el-checkbox></el-checkbox>
					<div>
						<el-button>全部数据批量授权</el-button>
						<el-button>脱敏数据批量授权</el-button>
					</div>
				</div>
				<el-card shadow="never">
					<div class="flex_r f_ai_c">
						<el-checkbox></el-checkbox>
						<img class="ml10 mr10" width="60" height="60" src="" alt="" srcset="">
						<div class="flex1">
							<div class="flex_r f_jc_b">
								<span>厦门熠冠卓</span>
								<div>
									<el-link type="primary">全部数据授权</el-link>
									<el-link type="primary">敏锐数据授权</el-link>
								</div>
							</div>
							<el-rate
								class="mt8 mb8"
								v-model="value2"
								:colors="colors">
							</el-rate>
							<ul class="flex_r">
								<li class="mr12">
									<span class="cr_999">合作次数：</span>
									<span>0</span>
								</li>
								<li class="mr12">
									<span class="cr_999">店铺数：</span>
									<span>0</span>
								</li>
								<li class="mr12">
									<span class="cr_999">合作商品数：</span>
									<span>0</span>
								</li>
								<li class="mr12">
									<span class="cr_999">销售量：</span>
									<span>0</span>
								</li>
								<li class="mr12">
									<span class="cr_999">销售额：</span>
									<span>0</span>
								</li>
								<li class="mr12">
									<span class="cr_999">主营类目：</span>
									<span>0</span>
								</li>
							</ul>
						</div>
					</div>
				</el-card>
			</div>
			<el-pagination
				class="fr pt4"
				background
				layout="total, prev, pager, next"
				:current-page="currentPage"
				:page-size="pageSize"
				:total="total"
			></el-pagination>
		</e-dialog>
	</div>
</template>

<script>
import {anchorOrganizationTableConf} from '@data/anchor'
export default {
	name: 'anchorOrganization',
	data() {
		return {
			anchorOrganizationTableConf,
			dataOrigin: {
				data: [
					{}
				]
			},
			tabPosition: '1',
			activeAuthName: '1',
			currentPage: 1,
			pageSize: 10,
			total: 0
		}
	},
	computed: {
		anchorOrganizationTableConfItem() {
			return anchorOrganizationTableConf[this.tabPosition]
		}
	},
	methods: {
		handleClick() {},

	}
}
</script>

<style lang="scss" scoped>
.search {
	position: relative;
	.form {
		position: absolute;
		right: 0;
		bottom: 0;
	}
}
</style>